<template>
  <b-card
    no-body
    class="bg-gradient">
    <b-card-body class="d-flex align-items-center">

      <div>
        <div>
          <h2 class="text-primary invoice-logo pl-0 ml-0">
            {{ user.currentCompany.brand }}
          </h2>
          <h5>{{ user.currentCompany.name }}</h5>
        </div>
        <p class="card-text mb-25">
          {{ user.currentCompany.address }}
        </p>
        <p class="card-text mb-0">
          {{ user.currentCompany.phone }}
        </p>
      </div>

      <!-- <b-avatar size="45" :src="user.currentCompany.logo" />
      <div class="ml-2 truncate">
        <h4 class="mb-25 font-weight-bolder">
          {{ user.currentCompany.name }}
        </h4>
        <span>{{ user.currentCompany.brand }}</span>
      </div> -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  // BCardText,
  BCardBody,
  // BAvatar
  // BLink,
  // BButton,
  // BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    // BCardText,
    BCardBody,
    // BAvatar
    // BLink,
    // BImg
    // BButton
  },
  directives: {
    Ripple
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    kFormatter
  }
}
</script>

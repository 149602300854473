<template>
  <section id="dashboard-ecommerce">
    <div class="mt-3 mb-3 text-center">
        <h1>Welcome to ARTOS</h1>
        <span>your Finance and Accounting Management System</span>
    </div>

    <b-row class="match-height">
      <b-col lg="8">
        <widget-congratulation :data="data.congratulations" />
      </b-col>

      <b-col lg="4">
        <widget-profile :data="data.congratulations" />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import WidgetCongratulation from './WidgetCongratulation.vue'
import WidgetProfile from './WidgetProfile.vue'
import { formatCurrency } from '@/utils/formatter'

export default {
  name: 'Analytics',
  components: {
    BRow,
    BCol,
    WidgetCongratulation,
    WidgetProfile,
  },
  data () {
    return {formatCurrency,
      data: {
      },
    }
  },
  // created () {
  //   // data
  //   this.$http
  //     .get('/reports/dashboard?dateStart=01/01/2021&dateEnd=31/10/2021')
  //     .then((response) => {
  //       this.analytics.cashbankBalance.bank.balance_view = formatCurrency(response.data.dataBank)
  //       this.analytics.cashbankBalance.cash.balance_view = formatCurrency(response.data.dataCash)
  //     })
  // }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
